<template>
  <div class="home">
    <Navbar />
    <Hero />
    <Spoiler />
    <Connections />
    <Achievements />
    <BetterDevs />
    <Journey />
    <Trust />
    <Account />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navigation/Navbar.vue';
import Hero from '@/components/Sections/Hero.vue';
import Connections from '@/components/Sections/Connections.vue';
import Achievements from '@/components/Sections/Achievements.vue';
import BetterDevs from '@/components/Sections/BetterDevs.vue';
import Spoiler from '@/components/Sections/Spoiler.vue';
import Journey from '@/components/Sections/Journey.vue';
import Trust from '@/components/Sections/Trust.vue';
import Account from '@/components/Sections/Account.vue';
import Footer from '@/components/Footer/Footer.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    Hero,
    Spoiler,
    Connections,
    Achievements,
    BetterDevs,
    Trust,
    Journey,
    Account,
    Footer,
  },
};
</script>
