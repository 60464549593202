<template>
  <footer id="footer">
    <div class="footer-content">
      <b-img id="logo-2"
            src="../../assets/images/logo.png"
            fluid

          ></b-img>
      <ul class="footer-links">
        <li><a href="#" data-aos="fade-left" data-aos-delay="400">Direitos autorais</a></li>
        <li><a href="#" data-aos="fade-left" data-aos-delay="400">Termos de uso</a></li>
        <li><a href="#" data-aos="fade-left" data-aos-delay="400">Políticas de privacidade</a></li>
      </ul>
      <ul class="social-icons">

        <li>
          <a href="https://www.instagram.com/medfuturo_/" data-aos="zoom-in" data-aos-delay="400"><Instagram /></a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCpjxfQij3e8ADWcQNvij11A" data-aos="zoom-in" data-aos-delay="400"><YouTube /></a>
        </li>
      </ul>
      <a href="#" class="help">Central de ajuda</a>
      <div class="boost">
          <p>Med Futuro</p>
          <a href="#navbar" class="to-top">
              <ToTop />
          </a>
      </div>
    </div>
  </footer>
</template>

<script>
import Instagram from '../Social/Instagram.vue';
import YouTube from '../Social/YouTube.vue';
import ToTop from '../SVG/ToTop.vue';

export default {
  name: 'Footer',
  components: {
    Instagram,
    YouTube,
    ToTop,
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/style.scss";

#footer {
  width: 100%;
  height: 100%;
  padding: 256px 0 80px 0;
  background: #0d0d0f;

  .footer-content {
    padding: 0 !important;
    display: grid;
    grid-template-columns: 184px 160px 2fr;
    justify-items: flex-end;
    row-gap: 32px;
    column-gap: 40px;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    ul {
      list-style: none;
    }

    svg {
        justify-self: flex-start;
        color: #F29400;
    }

    .footer-links {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      grid-area: 1 / 3;

      li {
        margin-left: 20px;

        a {
          font-size: 16px;
          line-height: 16px;
          color: $gray;
          -webkit-text-decoration: none;
          text-decoration: none;
        }
        #logo-2{
    width: 100px;
  }

      }
    }

    .social-icons {
      display: grid;
      grid-template-columns: repeat(4, 40px);
      grid-auto-flow: column;
      grid-gap: 8px;
      justify-self: flex-start;
      grid-area: 2 /1;
      margin: 0px;
      padding: 0px;

      li {
        background: #202024;
        width: 45px;
        height: 45px;
        border-radius: 5px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .help {
        color: $primary;
        font-weight: normal;
        font-size: 16px;
        line-height: 1;
        -webkit-text-decoration: none;
        text-decoration: none;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        grid-area: 2 / 2;
    }

    .boost {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        max-width: 170px;
        grid-area: 2 / 3;

        p {
            font-size: 14px;
            line-height: 24px;
            color: $green;
            margin: 0px;
            padding: 0px;
        }

        .to-top {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 5px;
            background: #202024;
            cursor: pointer;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
  }
}
</style>
