<!-- eslint-disable max-len -->
<template>
  <section id="connections">
    <b-container>
      <div class="content">
        <div class="image">
          <div class="profile-image">
            <b-img src="../../assets/images/connections.png" fluid data-aos="fade-right" data-aos-delay="200"></b-img>
          </div>
        </div>
        <div class="description">
          <div class="divider"></div>
          <h2 data-aos="fade-left" data-aos-delay="200">Conexão é a chave para as maiores oportunidades</h2>
          <p data-aos="fade-up" data-aos-delay="200">
            Tudo que você precisa para se destacar na carreira médica: Conhecimento e Oportunidade.
          </p>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'Connections',
};
</script>

<style lang="scss">
@import "../../assets/scss/style.scss";

</style>
