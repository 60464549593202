<template>
  <section id="hero">
  <div class="background">
    <b-container>
      <b-row>
        <h1 data-aos="fade-up">Eleve a sua carreira médica a um novo nível<b>.</b></h1>
        <div class="hero-content">
          <p data-aos="fade-right" data-aos-delay="200">
            O ambiente perfeito para você crescer e acessar oportunidades inimagináveis na Medicina
          </p>
           <b-button href="#bg-conta" variant="primary" class="btn__primary" data-aos="zoom-in">
           Embarcar no Foguete</b-button>
        </div>
        <div class="hero-shades">
          <b-img
            src="../../assets/images/hero.png"
            fluid
            data-aos="zoom-in"
          ></b-img>
        </div>
      </b-row>
    </b-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
};
</script>

<style lang="scss">
@import "../../assets/scss/style.scss";

#hero {
  padding: 7rem 0;

  h1 {
    font-size: 64px;
    line-height: 1;
    text-align: center;
    color: $white;
    margin: 5rem 0;
    font-size: 64px;
    width: 100%;
    max-width: 615px;
    margin: 0 auto;
  }

  .background{
    background: url('../../assets/images/hero2.png') no-repeat ;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding: 0;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    object-fit: cover;
    background-size: contain;
  }

  p {
    color: $gray;
    text-align: right;
    width: 100%;
    max-width: 320px;
    font-size: 18px;
    line-height: 156%;
  }

  b {
    color: $green !important;
  }

  .btn__primary {
    border: none;
    outline: none;
    width: 100%;
    border-radius: 5px;
    padding: 12px 32px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    max-width: 298px;
    height: 64px;
  }
  .hero-content {
    padding: 48px 0 120px 0;
    width: 100%;
    max-width: 665px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-shades {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-25%);
      -ms-transform: translateY(-25%);
      transform: translateY(-25%);
      width: 100%;
      height: 100%;
      z-index: -1;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #F29400 0%,
        rgba(18, 18, 20, 0) 100%
      );
      opacity: 0.4;
    }
  }
}
</style>
