<!-- eslint-disable max-len -->
<template>
  <section id="betterDevs">
    <b-container>
      <div class="content">
        <div class="image">
          <div class="devs-image">
            <b-img src="../../assets/images/community.png" fluid data-aos="fade-right" data-aos-delay="200"></b-img>
          </div>
        </div>
        <div class="description devs">
          <div class="divider"></div>
          <h2 data-aos="fade-left" data-aos-delay="200">Leve a sua carreira a um novo patamar</h2>
          <p data-aos="fade-up" data-aos-delay="200">
            Conecte-se com Médicos e Estudantes de todo Brasil e crie sua trajetória em grupo.
          </p>
          <p data-aos="fade-up" data-aos-delay="200">
            A comunidade Médicos do Futuro é um ambiente excepcional com oportunidades de trabalho,
            contatos e conteúdos fora do comum.
          </p>
          <a href="#" class="btn-create-account" data-aos="zoom-in" data-aos-delay="200">Quero conhecer</a>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'BetterDevs',
};
</script>

<style lang="scss">
@import "../../assets/scss/style.scss";

#betterDevs {
  .image {
    &:before,
    &:after {
      background: none !important;
    }

    .devs-image {
      max-width: 472px !important;
    }

  }
}
.btn-create-account {
        border: none;
        outline: none;
        color: #fff;
        background: $primary;
        width: 40%;
        border-radius: 5px;
        padding: 17px 37px;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        -webkit-text-decoration: none;
        text-decoration: none;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
</style>
