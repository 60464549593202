<template>
  <section id="trust">
    <b-container>
        <h2>FAQ - Tire suas dúvidas</h2>
        <div class="companies">
    <div id="app" class="p-4">

    <app-accordion class="mb-4">
      <template v-slot:title>
        <span class="font-semibold text-xl">Vocês têm um plano mensal?</span>
      </template>
      <template v-slot:content>
        <p>
          Não trabalhamos com o plano mensal, esse é um projeto de longo prazo,
          as características do Médico do Futuro são construídas aos poucos e
          por isso precisamos de membros comprometidos e com vontade de fazer
          a diferença na Medicina e nas suas vidas.
          Portanto, oferecemos apenas o plano anual até o presente momento.
        </p>
      </template>
    </app-accordion>

    <app-accordion class="mb-8">
      <template v-slot:title>
        <span class="font-semibold text-xl">O que são os Médicos do Futuro?</span>
      </template>
      <template v-slot:content>
        <p>
        Um ambiente completo para que as pessoas possam, assim como nós,
        se conectar com oportunidades, estudar e crescer na carreira através
        de uma jornada de aprendizado completa e contínua na Medicina.<br>

        Esse movimento foi criado inicialmente pelo Guilherme e Aldo, mas hoje
        toma forma em vários centros médicos do país.<br>

        </p>
      </template>
    </app-accordion>

        <app-accordion class="mb-4">
      <template v-slot:title>
        <span class="font-semibold text-xl">Afinal onde funciona Médicos do Futuro?</span>
      </template>
      <template v-slot:content>
        <p>
         Nossas interações ocorrem Online, nos nossos endereços, e Offline,
         quando nossos membros se encontram.<br>

          Mas na internet a comunidade funciona no Discord, nas reuniões e aulas
          semanais, e na nossa plataforma com fórum e aulas gravadas
          (app.medfuturo.com.br)<br>
        </p>
      </template>
    </app-accordion>

    <app-accordion class="mb-8">
      <template v-slot:title>
        <span class="font-semibold text-xl">Quais as formas de pagamento?</span>
      </template>
      <template v-slot:content>
        <p>
        Nós trabalhamos com os seguintes métodos de pagamento:<br>
        - Cartão de crédito<br>
        - Boleto<br>
        - Pix<br>
        </p>
      </template>
    </app-accordion>

    <app-accordion class="mb-4">
      <template v-slot:title>
        <span class="font-semibold text-xl">Acesso à comunidade</span>
      </template>
      <template v-slot:content>
        <p>
         O login e senha de acesso à comunidade são os mesmos da plataforma de aulas.<br>
         A plataforma ainda é nova e estamos em processo de migração das aulas.<br>

        Todos os membros da comunidade devem seguir os nossos Termos de uso e regras da
        comunidade.<br>
        O acesso ao Discord é enviado automaticamente após a compra, caso você não receba,
        iremos mandar por Whatsapp nos próximos dias.<br>

        Qualquer dúvida, entre em contato com o nosso suporte, estamos a disposição
        para ajudá-los.

        </p>
      </template>
    </app-accordion>

    <app-accordion class="mb-8">
      <template v-slot:title>
        <span class="font-semibold text-xl">Renovação</span>
      </template>
      <template v-slot:content>
        <p>
        As assinaturas via cartão de crédito são renovadas automaticamente
        no seu cartão de crédito cadastrado.<br>Para assinaturas via boleto,
        o boleto é enviado automaticamente para você por e-mail.
        </p>
      </template>
    </app-accordion>

    <app-accordion class="mb-4">
      <template v-slot:title>
        <span class="font-semibold text-xl">Cancelamento e reembolso </span>
      </template>
      <template v-slot:content>
        <p>
         A entrada na comunidade pode ser cancelada em até sete dias,
         sem que ela seja cobrada.<br> Cada turma possui um número limitado de
         vagas e existe o prazo 7 dias de garantia incondicional.<br>

         Caso você tenha perdido o prazo de cancelamento, não faremos reembolso.<br>
         Exigimos dos nossos membros o mesmo respeito com que os tratamos.<br>
         Portanto, fique atento ao prazo.<br>

        </p>
      </template>
    </app-accordion>

  </div>
        </div>
    </b-container>

  </section>
</template>

<script>
import AppAccordion from '../Footer/AppAccordion.vue';

export default {
  name: 'Trust',
  components: {
    AppAccordion,
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/style.scss";
div#app svg {
    width: 20px;
    float: right;
    margin: auto;
    padding-top: 6px;
}
.mb-4 p {
    padding: 30px;
    border-top: 1px #000;
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
    background: #151517;
}
.mb-8 p {
    padding: 30px;
    border-top: 1px #000;
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
    background: #151517;
}
div#app button {
    background: #1f1f23;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    text-align: left;
    padding: 20px;
    border: 0px;
}
.mb-8 {
    margin-bottom: 1.5rem !important;
}
#trust {
  margin: 10rem auto;
  max-width: 876px;

  h2 {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: $white;
    font-weight: 700;
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
  }

  .companies {
    color: $white;
    padding-top: 80px;

    div {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      width: 100%;
    }
  }
}
</style>
